import React, {Component} from 'react'
import PubSub from 'pubsub.js'
import {Link} from 'react-router-dom'
import moment from 'moment'
import humanizeTime from './../../../helpers/time/humanizeTime'
import FilterInvoices from './FilterInvoices'
import PagePagination from "../../../shared/PagePagination"
import TableLoading from './../../../shared/TableLoading'
import ActionReject from './action/ActionReject'
import ActionApprove from './action/ActionApprove'
import changeQuerySearch from '../../../helpers/routing/changeQuerySearch'
import {getListInvoiceValidation} from '../../../services/api/InvoicesValidationServices'

class InvoicePage extends Component {
    state = {
        data: [],
        error: '',
        loading: false,
        page: this.props.currentPage,
        pages: 1,
        limit: this.props.currentLimit,
        total: 0,
        filter: {
            filename: '',
            status: '',
            shipping_carrier: '',
            approval_status: '',
        }
    }

    _token = null

    componentDidMount() {
        this._handleGetListInvoice()
        this._token = PubSub.subscribe('LOAD_LIST_INVOICE', () => {
            this._handleGetListInvoice()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentPage !== this.props.currentPage) {
            this.setState({
                page: this.props.currentPage,
            }, this._handleGetListInvoice)
        }
    }

    componentWillUnmount() {
        this._token && PubSub.unsubscribe(this._token)
    }

    _handleGetListInvoice = async () => {
        try {
            const {page, limit, filter} = this.state
            const {status, filename, shipping_carrier, approval_status} = filter
            const query = {page,limit, status, filename, shipping_carrier, approval_status}

            this.setState({
                loading: true,
                error: ''
            })

            const {data, message, success} = await getListInvoiceValidation(query)

            if(!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            const {requests, pages, total} = data

            this.setState({
                data: requests,
                pages: pages,
                total: total,
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }

    }

    _onHandleChangePage = (e) => {
        this.setState({
            limit: e.target.value,
            page: 1
        }, () => {
            this._handleGetListInvoice()
        })

        changeQuerySearch({limit: e.target.value}, true)
        changeQuerySearch({page: 1}, true)
    }

    _onHandleChangeStatus = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                status: e.target.value
            },
            page: 1
        }, () => {
            this._handleGetListInvoice()
        })

        changeQuerySearch({page: 1}, true)
    }

    _validateDate = (date) => {
        const momentTime = moment(date)

        return humanizeTime(momentTime)
    }

    _getStatus = (value) => {
        switch (value) {
            case 'done':
                return <span className="badge badge-success">Done</span>
            case 'created':
                return <span className="badge badge-primary">Created</span>
            case 'processing':
                return <span className="badge badge-warning">Processing</span>
            default:
                return <span className="badge badge-danger">Failed</span>
        }
    }

    _switchPage = (page) => {
        this.setState({
            page: page
        }, () => {
            changeQuerySearch({page}, true)
            this._handleGetListInvoice()
        })
    }

    _onHandleChangeInput = (name, value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: value
            },
            page: 1
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this._handleGetListInvoice()
            }, 350)
        })

        changeQuerySearch({page: 1}, true)
    }

    _onHandleChangeShippingCarrier = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                shipping_carrier: e.target.value
            },
            page: 1
        }, () => {
            this._handleGetListInvoice()
        })

        changeQuerySearch({page: 1}, true)
    }

    _onHandleChangeApprovalStatus = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                approval_status: e.target.value
            },
            page: 1
        }, () => {
            this._handleGetListInvoice()
        })

        changeQuerySearch({page: 1}, true)
    }

    _onHandleResetFilter = name => {
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: ''
            }
        }, () => {
            this._handleGetListInvoice()
        })

        changeQuerySearch({page: 1}, true)
    }

    _getApprovedStatus = (status) => {
        switch (status) {
            case 'rejecting':
                return <span className="badge badge-secondary">Rejecting</span>
            case 'rejected':
                return <span className="badge badge-danger">Rejected</span>
            case 'approving':
                return <span className="badge badge-secondary">Approving</span>
            case 'approved':
                return <span className="badge badge-success">Approved</span>
            default:
                return
        }
    }

    render() {
        const {error, data, filter, limit, page, pages, total, loading} = this.state
        const {currentPage, listShippingCarrier} = this.props

        return (
            <div className="InvoicePage">
                <FilterInvoices
                    limit={limit}
                    filter={filter}
                    total={total}
                    onHandleChangePage={this._onHandleChangePage}
                    onHandleChangeStatus={this._onHandleChangeStatus}
                    onHandleChangeInput={this._onHandleChangeInput}
                    onHandleChangeShippingCarrier={this._onHandleChangeShippingCarrier}
                    onHandleChangeApprovalStatus={this._onHandleChangeApprovalStatus}
                    onHandleResetFilter={this._onHandleResetFilter}
                    listShippingCarrier={listShippingCarrier}
                />
                {error && (
                    <div className="text-danger my-3">{error}</div>
                )}
                <div className="wrapTable">
                    <table className="table">
                        <thead className="thead-light">
                        <tr>
                            <th className="colTitle">File name</th>
                            <th>Shipping carrier</th>
                            <th className="colStatus">Status</th>
                            <th className="colRequestNumber">Request number</th>
                            <th className="colCreated">Created</th>
                            <th className="colFinishAt">Finish at</th>
                            <th className="colNote">Note</th>
                            <th className="colActions">Approved status</th>
                        </tr>
                        </thead>
                        {
                            loading ?
                                <TableLoading columnQuantity={8}/>
                                :
                                <tbody>
                                {data.length > 0 ? (
                                    data.map(item => {
                                        const approvedStatus = item.approval_status !== undefined ? item.approval_status : ''
                                        return (
                                            <tr key={item._id}>
                                                <td>
                                                    <Link to={{
                                                        pathname: '/a/invoice-validation/' + item._id,
                                                        state: {currentPage: currentPage},
                                                    }}>
                                                        {item.filename}
                                                    </Link>
                                                </td>
                                                <td>{item.shipping_carrier}</td>
                                                <td>
                                                    {this._getStatus(item.status)}
                                                </td>
                                                <td>{item.request_number}</td>
                                                <td>{this._validateDate(item.created)}</td>
                                                <td>{this._validateDate(item.finished_at)}</td>
                                                <td>{item.note}</td>
                                                <td className="text-center colActions">
                                                    {
                                                        item.status === 'done' && (
                                                            approvedStatus !== '' ? (
                                                                <div>
                                                                    {this._getApprovedStatus(approvedStatus)}
                                                                </div>
                                                            ) : (
                                                                <div className="d-flex justify-content-center">
                                                                    <ActionApprove
                                                                        requestID={item._id}
                                                                        isDetail={false}
                                                                        requestNumber={item.request_number}
                                                                    />
                                                                    <ActionReject
                                                                        requestID={item._id}
                                                                        isDetail={false}
                                                                        requestNumber={item.request_number}
                                                                    />
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={8}>No result</td>
                                    </tr>
                                )}
                                </tbody>
                        }
                    </table>
                </div>
                <div className="TableNav mt-5 d-flex justify-content-end">
                    <PagePagination page={page} pages={pages} onChangePage={this._switchPage}/>
                </div>
            </div>
        )
    }
}

export default InvoicePage