import React from 'react'
import SupplierValidationDetailPage from "./SupplierValidationDetailPage"
import DashboardLayout from "../../dashboard/components/DashboardContainer"

function SupplierValidationDetailContainer(props) {
    const historyState = props.location.state
    const vHistoryState = Object.assign({}, historyState)
    const { currentPage = 1 , color = ''} = vHistoryState

    return (
        // <DashboardLayout path={props.match.path}>
        <div className='SupplierValidationDetailContainer'>
            <SupplierValidationDetailPage currentPage={currentPage} idValidation={props.match.params.id} color={color}/>
        </div>
        // </DashboardLayout>
    )
}

export default SupplierValidationDetailContainer
