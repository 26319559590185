import React, {Component} from 'react'
import TableData from "./TableData"
import {listRequestInvoice} from "../../../../services/api/ErrorShipping"
import PubSub from 'pubsub.js'
import PagePagination from "../../../../shared/PagePagination"
import TopTable from "./TopTable"

class FileErrorListPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: '',
            listFiles: [],
            page: 1,
            pages: 1,
            limit: 20,
            total: 0,
            status: '',
            carrier: ''
        }
    }

    _subscription = null

    componentDidMount() {
        this._fetchListRequest()
        this._subscription = PubSub.subscribe('FETCH_LIST_REQUEST', this._fetchListRequest)
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this._subscription)
    }

    _fetchListRequest = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {limit, page, carrier, status} = this.state
            const query = {
                limit,
                page,
                carrier,
                status
            }

            const {success, data, message} = await listRequestInvoice(query)

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            const {pages, total, invoiceExportRequests} = data

            this.setState({
                loading: false,
                listFiles: invoiceExportRequests,
                limit: data.limit,
                page: data.page,
                total: total,
                pages: pages
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _handleChangePage = (page) => {
        this.setState({
            page: page
        }, () => {
            this._fetchListRequest()
        })
    }

    _handleChangeLimit = (value) => {
        this.setState({
            limit: value,
            page: 1
        }, () => {
            this._fetchListRequest()
        })
    }

    setFilter = (prop, value) => {
        this.setState({
            [prop]: value
        }, this._fetchListRequest)
    }

    render() {
        const {loading, error, listFiles, page, pages, limit, total} = this.state
        const {listShippingCarrier} = this.props

        return (
            <div className='FileErrorListPage'>
                <TopTable setFilter={this.setFilter} limit={limit} total={total}
                          onChangeLimit={this._handleChangeLimit} reload={this._fetchListRequest} listShippingCarrier={listShippingCarrier}/>
                <TableData loading={loading} listFiles={listFiles} error={error} page={page} limit={limit} listShippingCarrier={listShippingCarrier}/>
                <div className="TableNav mt-3 d-flex justify-content-end">
                    <PagePagination page={page} pages={pages} onChangePage={this._handleChangePage}/>
                </div>
            </div>
        )
    }
}

export default FileErrorListPage