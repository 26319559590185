import React, { useState, useEffect } from 'react'
import InvoicePage from './InvoicePage'
import DashboardLayout from "../../dashboard/components/DashboardContainer"
import parseSearchQuery from '../../../helpers/routing/parseSearchQuery'
import CreatedInvoiceValidate from "./create/CreatedInvoiceValidate"
import { getListShippingCarrier } from '../../../services/api/ShippingCarrierServices'

function InvoiceContainer(props) {
    const [listShippingCarrier, setListShippingCarrier] = useState([])
    const urlParams = parseSearchQuery()
    const { page = 1, limit = 20 } = urlParams
    const currentPageParsed = Number(page)
    const currentLimitParsed = Number(limit)

    const fetchListShippingCarrier = async () => {
        try {
            const payload = {
                page: 1,
                limit: 10000,
            }
            const { data, success, message: mess } = await getListShippingCarrier(payload)
            if (!success) {
                throw new Error(mess)
            }
            const {shippingCarriers = []} = Object.assign({}, data)
            const newShippingCarriers = shippingCarriers.length > 0 ? shippingCarriers.map(i => ({
                value: i.slug,
                name: i.name,
            })).sort((a, b) => a.name.localeCompare(b.name)) : []
            setListShippingCarrier(newShippingCarriers)

        } catch (error) {
            this.setState({
                error: error.message,
            })
        }
    }

    useEffect(() => {
        fetchListShippingCarrier()
    },[])

    return (
        // <DashboardLayout path={props.match.path}>
        <div className="InvoiceContainer mt-3">
            <div className="d-sm-flex justify-content-between align-items-center">
                <h1 className="PageTitle mb-3">Invoice validation</h1>

                <CreatedInvoiceValidate listShippingCarrier={listShippingCarrier}/>
            </div>
            <div className="site-layout-background p-3">
                <InvoicePage
                    currentPage={currentPageParsed}
                    currentLimit={currentLimitParsed}
                    listShippingCarrier={listShippingCarrier}
                />
            </div>
        </div>
        // </DashboardLayout>
    )
}

export default InvoiceContainer
