import React, {Component} from 'react'
import PropTypes from 'prop-types'
import humanizeTime from "../../../../../helpers/time/humanizeTime"
import DownloadInvoice from "./DownloadInvoice"
import StatusFile from "./StatusFile"
import {INVOICE_CARRIERS} from "../../../../../helpers/consts"

class TableRow extends Component {
    render() {
        const {index, item, page, limit, listShippingCarrier} = this.props
        const carrierBySlug = listShippingCarrier.reduce((mem, carrier) => {
            return {
                ...mem,
                [carrier.value]: carrier
            }
        }, {})
        
        return (
            <tr className='TableRow'>
                <td>{limit * (page - 1) + index + 1}</td>
                <td>{item.filename}</td>
                <td>{humanizeTime(item.created)}</td>
                <td>{carrierBySlug[item.carrier] && carrierBySlug[item.carrier].name || '-'}</td>
                <StatusFile status={item.status}/>
                <DownloadInvoice id={item._id} status={item.status}/>
            </tr>
        )
    }
}

TableRow.propTypes = {
    limit: PropTypes.number,
    page: PropTypes.number,
    index: PropTypes.number,
    item: PropTypes.object
}

export default TableRow