import React, {useState, useEffect} from 'react'
import CreatedSupplierValidateModal from "./CreatedSupplierValidateModal"
import getHistory from "../../../../helpers/routing/getHistory"

const history = getHistory()
const hasKey = 'upload-supplier-invoice'

function CreatedSupplierValidate(props) {
    const {suppliers} = props
    const [isOpen, _setToggleModal] = useState(history.location.hash === `#${hasKey}`)

    function _onChangeToggleModal() {
        _setToggleModal(!isOpen)
    }

    useEffect(() => {
        if (isOpen) {
            history.push({
                hash: hasKey,
                search: history.location.search
            })
        } else {
            history.push({
                hash: '',
                search: history.location.search
            })
        }
    }, [isOpen])

    return (
        <div className='CreatedInvoiceValidate mb-3'>
            <button type="primary" className="btn btn-primary" onClick={_onChangeToggleModal}>
                Create supplier validation
            </button>

            {
                isOpen && <CreatedSupplierValidateModal isOpen={isOpen} onToggleModal={_onChangeToggleModal} suppliers={suppliers}/>
            }
        </div>
    )
}

export default CreatedSupplierValidate
