import React, {Component} from 'react'
import Promise from 'bluebird'
import {message} from 'antd'
import Files from "react-butterfiles"
import {putInvoiceFile, downloadTemplate} from '../../../services/api/ErrorShipping'
import PubSub from 'pubsub.js'
import {INVOICE_CARRIERS} from '../../../helpers/consts'

class ShippingErrorForm extends Component {
    state = {
        files: [],
        errors: [],
        error: '',
        loading: false,
    }

    _handleSelectedFiles = (files) => {
        this.setState({
            files
        }, () => {
            this._bulkUploadFiles(files)
        })
    }

    _bulkUploadFiles = async (files) => {
        await Promise.each(files, async item => {
            await this._handleUploadFile(item)
        })
    }

    _handleUploadFile = async (item) => {
        this.setState({
            error: '',
            loading: true
        })

        try {
            const file = item.src.file
            const fileType = file.name.split('.').pop().trim()

            if (fileType === 'csv') {
                const formData = new FormData()

                formData.append('invoice_export', file)
                formData.append('carrier', this.state.carrier)

                const {success, message: error} = await putInvoiceFile(formData)

                if (success) {
                    this.setState({
                        files: [],
                        loading: false
                    }, () => {
                        message.success('Upload file success')
                        PubSub.publish('FETCH_LIST_REQUEST')
                    })
                } else {
                    return this.setState({
                        error: error,
                        loading: false,
                        files: [],
                    })
                }
            } else {
                return this.setState({
                    error: `Kiểu file ${fileType} không hỗ trợ. Vui lòng chọn kiểu file là csv.`,
                    loading: false,
                    files: []
                })
            }
        } catch (e) {
            this.setState({
                files: [],
                error: e.message,
                loading: false
            })
        }
    }

    _handleSelectedError = (errors) => {
        this.setState({
            errors
        })
    }

    _handleDownloadTemplate = (e) => {
        e.preventDefault()
        window.location.href = downloadTemplate()
    }

    setCarrier = (e) => {
        this.setState({
            carrier: e.target.value
        })
    }

    render() {
        const {error, loading, carrier} = this.state
        const {listShippingCarrier} = this.props

        return (
            <div className="FormWrap">
                <div className="Form">
                    <div className="row">
                        <div>
                            <label className="mb-0 mt-1 font-weight-bold">Upload template to export</label>
                        </div>
                        <div className="col-sm-8">
                            <div className="Input">
                                <Files
                                    multiple={false} maxSize="20mb" multipleMaxSize="100mb"
                                    onSuccess={this._handleSelectedFiles}
                                    onError={this._handleSelectedError}
                                    multipleMaxCount={1}
                                >
                                    {({browseFiles}) => {
                                        return (
                                            <div className="d-flex align-items-center">
                                                <div className="File">
                                                    {this.state.files.map(file => (
                                                        <div
                                                            key={file.name}>{(file.name).length > 70 ? (file.name).substr(1, 70) + '...' : file.name}</div>
                                                    ))}
                                                    {this.state.errors.map(err => (
                                                        <div key={err.id}>
                                                            {
                                                                err.file
                                                                    ? (<span>{err.file.name} - {err.type}</span>)
                                                                    : (err.type)
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                                <select onChange={this.setCarrier}>
                                                    <option value="">Select carrier</option>
                                                    {
                                                        listShippingCarrier.map(carrier => {
                                                            return <option key={carrier._id} value={carrier.value}>{carrier.name}</option>
                                                        })
                                                    }
                                                </select>
                                                <button disabled={loading || !carrier} className="btn btn-dark border-0"
                                                        onClick={browseFiles}>
                                                    {loading ? 'Uploading...' : 'Browse'}
                                                </button>
                                            </div>
                                        )
                                    }}
                                </Files>
                            </div>
                            {error && <div className="text-danger mt-1">{error}</div>}
                            <button onClick={this._handleDownloadTemplate}
                                    className="btn btn-link my-3 d-block p-0 text-decoration-none">Download template
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ShippingErrorForm