import React, {Component} from 'react'
import ShippingErrorForm from './ShippingErrorForm'
import FileErrorListContainer from "./file-error-lists/FileErrorListContainer"
import { getListShippingCarrier } from '../../../services/api/ShippingCarrierServices'

class ShippingErrorPage extends Component {
    state = {
        listShippingCarrier: [],
    }

    componentDidMount(){
        this.fetchListShippingCarrier()
    }

    fetchListShippingCarrier = async () => {
        try {
            const payload = {
                page: 1,
                limit: 10000,
            }
            const { data, success, message: mess } = await getListShippingCarrier(payload)
            if (!success) {
                throw new Error(mess)
            }
            const {shippingCarriers = []} = Object.assign({}, data)
            const newShippingCarriers = shippingCarriers.length > 0 ? shippingCarriers.map(i => ({
                value: i.slug,
                name: i.name,
            })).sort((a, b) => a.name.localeCompare(b.name)) : []

            this.setState({listShippingCarrier: newShippingCarriers})

        } catch (error) {
            this.setState({
                error: error.message,
            })
        }
    }

    render() {
        const {listShippingCarrier} = this.state

        return (
            <div className="ExportErrorShippingPage">
                <div className="ExportErrorShippingForm">
                    <ShippingErrorForm listShippingCarrier={listShippingCarrier}/>
                </div>
                <div className="ListErrorShipping">
                    <FileErrorListContainer listShippingCarrier={listShippingCarrier}/>
                </div>
            </div>
        )
    }

}

export default ShippingErrorPage