import React, {useEffect, useState} from 'react'
import SupplierPage from './SupplierPage'
import DashboardLayout from "../../dashboard/components/DashboardContainer"
import parseSearchQuery from '../../../helpers/routing/parseSearchQuery'
import CreatedSupplierValidate from "./create/CreatedSupplierValidate"
import {getSuppliers} from "../../../services/api/FulfillmentServices"

function SupplierContainer(props) {
    const urlParams = parseSearchQuery()
    const { page = 1, limit = 20 } = urlParams
    const currentPageParsed = Number(page)
    const currentLimitParsed = Number(limit)
    const [suppliers, setSuppliers] = useState([])

    const _handleGetListSupplier = async () => {
        try {
            setSuppliers([])
            const {data, message, success} = await getSuppliers()
            if (!success) {
                return console.log(message)
            }

            const {suppliers} = data
            setSuppliers(suppliers)
        } catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        _handleGetListSupplier()
    },[])

    return (
        // <DashboardLayout path={props.match.path}>
        <div className="InvoiceContainer mt-3">
            <div className="d-sm-flex justify-content-between align-items-center">
                <h1 className="PageTitle mb-3">Supplier validation</h1>
                <CreatedSupplierValidate filters={urlParams} suppliers={suppliers}/>
            </div>
            <div className="site-layout-background p-3">
                <SupplierPage
                    filters={urlParams}
                    suppliers={suppliers}
                    currentPage={currentPageParsed}
                    currentLimit={currentLimitParsed}
                />
            </div>
        </div>
        // </DashboardLayout>
    )
}

export default SupplierContainer
